<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <!-- <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear> -->
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    :menu-props="{closeOnContentClick:false, offsetOverflow:false}"
                                    solo
                                    clearable 
                                    v-model="category" 
                                    :disabled="searchOn"
                                    :items="categories" 
                                    item-value="category_id"
                                    item-text="cat_desc" 
                                    label="Category" 
                                    chips
                                    small-chips
                                    :deletable-chips=true
                                    multiple
                                    :hide-details=true
                                    @change="(event) => getFilter('category')">>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    solo
                                    clearable 
                                    v-model="machType" 
                                    :disabled="searchOn"
                                    :items="machTypes" 
                                    item-value="mach_type"
                                    item-text="mach_type" 
                                    label="Mach Type" 
                                    chips
                                    small-chips
                                    :deletable-chips=true
                                    multiple
                                    :hide-details=true
                                    @change="(event) => getFilter('machType')">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    solo
                                    clearable 
                                    v-model="jenis" 
                                    :disabled="searchOn"
                                    :items="jeniss" 
                                    item-value="Jenis"
                                    item-text="Jenis" 
                                    label="Jenis" 
                                    chips
                                    small-chips
                                    :deletable-chips=true
                                    multiple
                                    :hide-details=true
                                    @change="(event) => getFilter('jenis')">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    solo
                                    clearable 
                                    v-model="thick" 
                                    :disabled="searchOn"
                                    :items="thicks" 
                                    item-value="Tebal"
                                    item-text="Tebal" 
                                    label="Tebal" 
                                    chips
                                    small-chips
                                    :deletable-chips=true
                                    multiple
                                    :hide-details=true
                                    @change="(event) => getFilter('tebal')">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    solo
                                    clearable 
                                    v-model="width" 
                                    :disabled="searchOn"
                                    :items="widths" 
                                    item-value="Lebar"
                                    item-text="Lebar" 
                                    label="Lebar" 
                                    chips
                                    small-chips
                                    :deletable-chips=true
                                    multiple
                                    :hide-details=true
                                    @change="(event) => getFilter('lebar')">
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  
                                    class="ma-0 pa-0" 
                                    solo
                                    clearable 
                                    v-model="product" 
                                    :disabled="searchOn"
                                    :items="products" 
                                    item-value="NamaBarang"
                                    item-text="NamaBarang" 
                                    label="Products Desc" 
                                    chips
                                    small-chips
                                    :deletable-chips=true
                                    multiple
                                    :hide-details=true
                                    @change="(event) => getFilter('namaBarang')">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete 
                                    class="ma-0 pa-0" 
                                    :disabled=searchOn
                                    solo 
                                    clearable 
                                    v-model="type" 
                                    :items="types" 
                                    label="Type"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="start_date"
                                        :disabled=searchOn
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="start_date"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="end_date"
                                        :disabled=searchOn
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="end_date"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="1">
                                <v-text-field
                                    solo
                                    clearable
                                    v-model="top"
                                    :disabled=searchOn
                                    label="Top"
                                ></v-text-field>
                            </v-col>

                            <!-- <v-col cols="12" xs="12" sm="3" md="7">
                            </v-col> -->
                        
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn 
                                    :loading="searchOn"
                                    :disabled="searchOn"
                                    color="info" elevation="2" large @click="getTopEnd()">Search
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6">
                                <v-card>
                                    <v-card-title>Top {{top}} Best</v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        fixed-header
                                                        :headers="headerDataTable"
                                                        :items="topTenBest"
                                                        :loading="loading"
                                                        :items-per-page="topNumber"
                                                        hide-default-footer
                                                        loading-text="Please wait, retrieving data"
                                                        class="text-sm-left">
                                                    </v-data-table>

                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6">
                                <v-card >
                                    <v-card-title>Top {{top}} Worst</v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        fixed-header
                                                        :headers="headerDataTableWorst"
                                                        :items="topTenWorst"
                                                        :loading="loading"
                                                        :items-per-page="topNumber"
                                                        hide-default-footer
                                                        loading-text="Please wait, retrieving data"
                                                        class="font-weight: 100">
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {master} from "../../../backend-api/kmb/master"
import {order} from "../../../backend-api/kmb/order"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KMB',
                disabled: false,
                href: '/admin/kmb/',
                },
                {
                text: 'Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Order Dashboard by Depo',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            filterRadio:"Periodicaly",
            isSearch:false,
            category:[],
            categories:[],
            product:[],
            products:[],
            thick:[],
            thicks:[],
            jenis:[],
            jeniss:[],
            width:[],
            widths:[],
            machType:[],
            machTypes:[],
            type:'',
            types:['Project', 'Non Project'],
            modal: false,
            modal_to: false,
            start_date:'',
            end_date:'',
            top:'10',
            topNumber:10,
            topTenBest:[],
            topTenWorst:[],
            headerDataTable:[
                {
                    text: 'Rank',
                    align: 'start',
                    sortable: false,
                    value: 'no',
                    class: "grey"
                },
                {
                    text: 'Office',
                    sortable: false,
                    value: 'OFFICE',
                    class: "grey text-align: center"
                },
                { 
                    text: 'Qty Order (M)', 
                    value: 'qty', 
                    sortable: false, 
                    class: "grey text-align: center"},
            ],
            headerDataTableWorst:[
                {
                    text: 'Rank',
                    align: 'start',
                    sortable: false,
                    value: 'no',
                    class:'error',
                },
                {
                    text: 'Office',
                    sortable: false,
                    value: 'OFFICE',
                    class: "error text-align: center"
                },
                { 
                    text: 'Qty Order (M)', 
                    value: 'qty', 
                    sortable: false, 
                    class:'error text-align: center',},
            ],
            data_var: {
                entity_id : 'KMB',
                appl_id : 'WEBKMB'
            },
            editable:false,
            varValue:null,
            searchOn:false,
        }
    },
    mounted() {
        var today = new Date();
        var yearMonth = today.toISOString().slice(0, 7).replace('-', '');
        var a = yearMonth - 1;
        var year = a.toString().substring(0,4);
        var month = a.toString().substring(4,6);
        var periode = year + "-"+month
        this.start_date = periode
        this.end_date = periode

        this.getFilter();
    },
    methods:{
        formatNumber(number, minFrac, maxFrac) {
            var a = parseFloat(number)
            return a.toLocaleString(undefined, {
                minimumFractionDigits: minFrac,
                maximumFractionDigits: maxFrac
            });
        },
        getFilter(value){
            switch(value) {
                case "category":
                    this.machType= [];
                    this.jenis = [];
                    this.thick = [];
                    this.width = [];
                    this.product = [];
                    this.getMachType();
                    this.getJenis();
                    this.getTebal();
                    this.getLebar();
                    this.getNamaBarang();
                    break;
                case "machType":
                    this.jenis = [];
                    this.thick = [];
                    this.width = [];
                    this.product = [];
                    this.getJenis();
                    this.getTebal();
                    this.getLebar();
                    this.getNamaBarang();
                    break;
                case "jenis":
                    this.thick = [];
                    this.width = [];
                    this.product = [];
                    this.getTebal();
                    this.getLebar();
                    this.getNamaBarang();
                    break;
                case "tebal":
                    this.width = [];
                    this.product = [];
                    this.getLebar();
                    this.getNamaBarang();
                    break;
                case "lebar":
                    this.product = [];
                    this.getNamaBarang();
                    break;
                case "namaBarang":
                    break;
                default:
                    this.category = [];
                    this.machType= [];
                    this.jenis = [];
                    this.thick = [];
                    this.width = [];
                    this.product = [];
                    this.getCategory();
                    this.getMachType();
                    this.getJenis();
                    this.getTebal();
                    this.getLebar();
                    this.getNamaBarang();
                    break;
            }
        },
        async getCategory(value){
        var url = ''
          var data = await master.fetchCategory(url, null, false, false, false)
          this.categories = data.data.data;
        },
        async getMachType(value){
          var url = '/api/kmb/order/filter-mach-type'
          var reqBody = {
            "category_id": this.category
          }
          var data = await backendApi.fetchCore(url, reqBody, false, false, false)
          this.machTypes = data.data.data;
        },
        async getJenis(value){
          var url = '/api/kmb/order/filter-jenis'
          var reqBody = {
            "category_id": this.category,
            "mach_type": this.machType
          }
          var data = await backendApi.fetchCore(url, reqBody, false, false, false)
          this.jeniss = data.data.data;
        },
        async getTebal(value){
          var url = '/api/kmb/order/filter-tebal'
          var reqBody = {
            "category_id": this.category,
            "mach_type": this.machType,
            "jenis": this.jenis
          }
          var data = await backendApi.fetchCore(url, reqBody, false, false, false)
          this.thicks = data.data.data;
        },
        async getLebar(value){
          var url = '/api/kmb/order/filter-lebar'
          var reqBody = {
            "category_id": this.category,
            "mach_type": this.machType,
            "jenis": this.jenis,
            "Tebal": this.thick
          }
          var data = await backendApi.fetchCore(url, reqBody, false, false, false)
          this.widths = data.data.data;
        },
        async getNamaBarang(value){
          var url = '/api/kmb/order/filter-nama-barang'
          var reqBody = {
            "category_id": this.category,
            "mach_type": this.machType,
            "jenis": this.jenis,
            "Tebal": this.thick,
            "Lebar": this.width
          }
          var data = await backendApi.fetchCore(url, reqBody, false, false, false)
          this.products = data.data.data;
        },
        async getTopEnd(){
          this.loading = true
          this.searchOn = true
          this.topNumber = parseInt(this.top)
          var sd = ''
          var ed = ''
          if(this.start_date != '' && this.start_date != null){
            sd = `${this.start_date.substring(0,4)}${this.start_date.slice(-2)}`
          }
          if(this.end_date != '' && this.end_date != null){
            ed = `${this.end_date.substring(0,4)}${this.end_date.slice(-2)}`
          }
            var division = '';
            if(this.type != '' && this.type != null){
                if(this.type == 'Project'){
                    division = 'R'
                }
                if(this.type == 'Non Project'){
                    division = 'NR'
                }
            }
                
          var reqBody = {
            "category_id": this.category,
            "mach_type": this.machType,
            "jenis": this.jenis,
            "Tebal": this.thick,
            "Lebar": this.width,
            "top": this.top,
            "periode_pesan_start": sd,
            "periode_pesan_end": ed,
            "order_sort" : "desc",
            "division": division,
            "NamaBarang": this.product
          }
          var url = '/api/kmb/order/by-depo'
          var dataBest = await backendApi.fetchCore(url, reqBody, false, false, false)

          var topTenData = dataBest.data.data;
          this.topTenBest = []
          topTenData.forEach((element, index) => {
            this.topTenBest.push({
                no: index+1,
                OFFICE: element.OFFICE,
                // qty: element.qty.toLocaleString(undefined, {
                //     minimumFractionDigits: 0,
                //     maximumFractionDigits: 0
                // })
                qty: this.$store.getters.convertToCurrencyNoFrac(element.qty)
            })
          })
          
          var reqBody = {
            "category_id": this.category,
            "mach_type": this.machType,
            "jenis": this.jenis,
            "Tebal": this.thick,
            "Lebar": this.width,
            "top": this.top,
            "periode_pesan_start": sd,
            "periode_pesan_end": ed,
            "order_sort" : "asc",
            "division": division,
            "NamaBarang": this.product
          }
          var dataWorst = await backendApi.fetchCore(url, reqBody, false, false, false)
          var topTenWorstData = dataWorst.data.data;
          this.topTenWorst = []
          topTenWorstData.forEach((element, index) => {
            this.topTenWorst.push({
                no: index+1,
                OFFICE: element.OFFICE,
                // qty: element.qty.toLocaleString(undefined, {
                //     minimumFractionDigits: 0,
                //     maximumFractionDigits: 0
                // })
                qty: this.$store.getters.convertToCurrencyNoFrac(element.qty)
            })
          })
          this.loading = false
          this.searchOn = false
        },
    },
    watch:{
        // searchRegion (val) {
        //     val && val !== this.region && this.getRegion(val)
        // },
        // searchOffice (val) {
        //     val && val !== this.office && this.getOffice(val)
        // },
        // searchMill (val) {
        //     val && val !== this.mills && this.getMill(val)
        // },
        // searchbarangJadi (val) {
        //     val && val !== this.category && this.getBarangJadi(val)
        // },
        // searchSales (val) {
        //     val && val !== this.sales && this.getSales(val)
        // },
    },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&display=swap');

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
    font-family: 'Roboto Flex';
}
</style>