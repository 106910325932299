import {backendApi} from "../backend-api-sr"

export const order = (() =>{
    const topEnd = ( async (subPath) => {
        var url = '/api/kmb/order'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {topEnd};
})()